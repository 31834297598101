import React from "react";
import { useState } from "react";
import { styled } from "styled-components";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
const faqdata2 = [
  {
    q: "What is SAP Business One, and how does it benefit small and medium-sized businesses (SMBs)?",
    a: "This question aims to provide an overview of SAP Business One and highlight its advantages, especially in the context of serving the needs of small and medium-sized enterprises.",
  },
  {
    q: "How does SAP Business One differ from other ERP solutions in the market?",
    a: "This question helps users understand the unique features and capabilities that set SAP Business One apart from other Enterprise Resource Planning (ERP) solutions available, emphasizing its strengths and advantages.",
  },
  {
    q: "What modules are included in SAP Business One, and can they be customized to fit our specific business requirements?",
    a: "Here, the focus is on the modular structure of SAP Business One, exploring the various functionalities it offers and addressing the potential for customization to suit the diverse needs of different businesses.",
  },
  {
    q: "How does SAP Business One support integration with other business applications and third-party systems?",
    a: "This question delves into the interoperability of SAP Business One, exploring its capabilities for seamless integration with other software solutions and external systems, which is crucial for a comprehensive business technology ecosystem.",
  },
  {
    q: "What is the implementation process for SAP Business One, and how long does it typically take to go live?",
    a: "Addressing the implementation timeline and process provides potential users with insights into what to expect when adopting SAP Business One, helping them plan for the integration into their business operations effectively.",
  },
];

const faqdata = [
  {
    q: "What is a dedicated server, and why might my business need one?",
    a: "A dedicated server is a physical server exclusively allocated to a single client. Unlike shared hosting, where multiple users share resources, dedicated servers provide unparalleled performance, security, and control. Your business might need a dedicated server if you require robust hosting forresource-intensive applications, high traffic websites, or if you prioritize enhanced security and customization options.",
  },
  {
    q: "How does dedicated server hosting differ from other hosting options?",
    a: "Dedicated server hosting stands out by offering an entire server exclusively for your business. This contrasts with shared hosting, where resources are shared among multiple users, and virtual private servers (VPS), which allocate dedicated portions of a server to different users. Dedicated servers provide the highest level of control, customization, and performance, making them ideal for businesses with specific requirements.",
  },
  {
    q: "What level of technical expertise is required to manage a dedicated server?",
    a: "While having technical knowledge can be beneficial, many hosting providers offer managed dedicated server solutions. With managed services, the hosting provider handles routine tasks such as server maintenance, security updates, and backups. This allows businesses without extensive technical expertise to leverage the benefits of a dedicated server while focusing on their core operations.",
  },
  {
    q: "How scalable are dedicated servers, and can resources be upgraded easily?",
    a: "Dedicated servers are highly scalable. Most hosting providers offer flexible plans that allow youto upgrade or downgrade resources as your business needs change. Whether you need additional processing power, storage, or bandwidth, the scalability of dedicated servers ensures that you can adapt your hosting environment to match your evolving requirements.",
  },
  {
    q: "What security measures are in place for dedicated servers, and how is data protected?",
    a: "Dedicated servers come with robust security features. These often include firewalls, intrusion detection systems, and regular security audits. Data protection is ensured through encryption protocols, secure data centers, and backup solutions. It's important to choose a hosting provider with a strong commitment to security to safeguard your business-critical data effectively.",
  },
];
const Faqs = () => {
  return (
    <section className="faqs" id="faqs">
      <h1>FAQs</h1>
      <p>
        Certainly! Here are Ten frequently asked questions (FAQs) about The SAP B1 and
        dedicated servers along with their respective answers
      </p>
      <h2>SAP</h2>
      <Accordion allowMultiple>
        {faqdata2.map(({ q, a }, i) => (
          <AccordionItem
            header={
              <div className="faq-header">
                {q} <BsChevronDown />
              </div>
            }
            key={i}
          >
            {a}
          </AccordionItem>
        ))}
      </Accordion>
      <h2>Dedicated Servers</h2>
      <Accordion allowMultiple>
        {faqdata.map(({ q, a }, i) => (
          <AccordionItem
            header={
              <div className="faq-header">
                {q} <BsChevronDown />
              </div>
            }
            key={i}
          >
            {a}
          </AccordionItem>
        ))}
      </Accordion>
      <p className="faq-text">
        Feel free to customize these FAQs and answers to better align with the
        specific offerings and details of your dedicated server services.
      </p>
    </section>
  );
};

// const FaqStyle = styled.section`
//   @media screen and (max-width: 450px) {
//     margin-top: 72px;
//     margin-bottom: 72px;
//   }
//   .faq-container {
//     max-width: 1440px;
//     ${"" /* padding: 0 10rem; */}
//     margin: auto;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     @media screen and (max-width: 450px) {
//       padding: 0 2.4rem;
//     }

//     .faqs {
//       ${"" /* margin-top: 50px; */}
//       display: flex;
//       flex-direction: column;
//       ${"" /* gap: 20px; */}
//       width: 842px;
//       @media screen and (max-width: 450px) {
//         width: 350px;
//       }
//     }
//   }
// `;
export default Faqs;

// const FaqCard = ({ q, a }) => {
//   const [isActive, setIsActive] = useState(false);
//   const handleClick = () => {
//     setIsActive(~isActive);
//   };
//   return (
//     <FaqCardStyle toggle={`${isActive}`}>
//       <div className="accordion" onClick={handleClick}>
//         <div className="heading">
//           <div className={isActive ? "title selected" : "title"}> {q}</div>
//           <div className={isActive ? "icon selected" : "icon"}>
//             {isActive ? <BsChevronUp /> : <BsChevronDown />}
//           </div>
//         </div>
//         {/* <div className={isActive ? "panel panel-open" : "panel"}>{a}</div>   */}
//       </div>
//     </FaqCardStyle>
//   );
// };

// const FaqCardStyle = styled.div`
//   @keyframes fade-in {
//     from {
//       opacity: 0;
//       height: 0;
//     }
//     to {
//       opacity: 1;
//       height: 300px;
//     }
//   }
//   @keyframes fade-out {
//     from {
//       opacity: 0;
//       height: 0;
//     }
//     to {
//       opacity: 0;
//       height: 0;
//     }
//   }
//   .accordion {
//     background-color: white;
//     border: 1px solid #e8e8e8;
//     color: #5e6362;
//     //padding: 20px 16px;
//     width: 100%;
//     position: relative;
//     /*max-height: 159px;*/
//     @media screen and (max-width: 450px) {
//       padding: 12px 16px;
//       max-height: fit-content;
//     }
//     &:hover {
//       cursor: pointer;
//     }
//     .heading {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       gap: 4px;
//       font-size: 2rem;
//       @media screen and (max-width: 450px) {
//         font-size: 1.3rem;
//       }
//       &:hover {
//         color: #0b64e8;
//       }

//       & > .selected {
//         color: #0b64e8;
//       }

//       .icon {
//         &:hover {
//           cursor: pointer;
//         }
//       }
//     }
//   }

//   .panel {
//     font-size: 1.6rem;
//     max-height: 0px;
//     opacity: 0;
//     visibility: hidden;
//     transition: all 0.5s ease-in-out;
//     @media screen and (max-width: 450px) {
//       font-size: 1.3rem;
//     }
//   }
//   .panel-open {
//     /* animation:fade-in 2s forwards; */
//     /* margin-top: 21px; */
//     visibility: visible;
//     opacity: 1;
//     //max-height: 100%;
//     transition: all 0.5s ease-in;
//   }
// `;
