import React from 'react'

const Footerbar = () => {
  return (
       <div className="footerbar">
       <p>&#169;2023 - Saprise Consultancy  |   All right reserved</p>
      </div> 
  )
}

export default Footerbar