import React from "react";
import Quality from "../assets/new/quality.png";
import Global from "../assets/new/global.png";
import Price from "../assets/new/price.png";
import Support from "../assets/new/support.png";
import Security from "../assets/new/security.png";
import Award from "../assets/new/award.png";

const Difference = () => {
  return (
    <section className="differences" id="Why Choose Us?">
      <h1>Why SAPRISE Is Different?</h1>
      <div className="servicesparent">
        <div className="services__card">
          <img src={Quality} alt="no img found" />
          <h1>Expertise</h1>
          <p>
            Our team comprises seasoned professionals with a wealth of
            experience in web development, design, and digital marketing. Trust
            us to deliver solutions that align with industry best practices and
            the latest trends.
          </p>
        </div>
        <div className="services__card">
          <img src={Global} alt="no img found" />
          <h1>Tailored Solutions</h1>
          <p>
            Recognizing that every business is unique, we offer bespoke
            solutions tailored to your specific requirements. Our collaborative
            approach ensures that your vision is at the core of every project
          </p>
        </div>
        <div className="services__card">
          <img src={Price} alt="no img found" />
          <h1> Expertise and Experience</h1>
          <p>
            Our team boasts extensive expertise in managing dedicated servers,
            coupled with years of experience in delivering cloud management
            solutions for businesses of all sizes.
          </p>
        </div>
      </div>
      <div className="servicesparent">
        <div className="services__card">
          <img src={Support} alt="no img found" />
          <h1> Website Development</h1>
          <p>
            Empower your online presence with our cutting-edge website
            development services. Our team of skilled developers crafts custom
            websites that not only look stunning but also function seamlessly.
          </p>
        </div>
        <div className="services__card">
          <img src={Security} alt="no img found" />
          <h1>Website Design</h1>
          <p>
            Our expert designers understand the art of creating visually
            appealing and user-friendly websites.we design websites that not
            only capture attention but also engage your audience, driving
            meaningful interactions.
          </p>
        </div>
        <div className="services__card">
          <img src={Award} alt="no img found" />
          <h1>Digital Marketing</h1>
          <p>
            Our digital marketing strategies are geared towards enhancing your
            brand's online presence, increasing traffic, and driving
            conversions. From search engine optimization (SEO) to social media
            marketing and beyond.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Difference;
