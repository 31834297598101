import React from "react";

const Capabilities = () => {
  return (
    <section className="capabilities" id="services">
      <h1>Services & Capabilities</h1>
      <p>
        we use professional services and proven techniques based on more than 05
        years of expertise, hundreds of successful SAP services, business
        technology platform implementations, and industry best practices.
      </p>
      <div className="capabilities_row">
        {/* left column of the row */}
        <div className="left_col">
          <div className="blurb">
            <h4>Tailored Solutions:</h4>
            <p>
              We recognize that each business has unique requirements. Our
              dedicated server provisioning services are customized to align
              precisely with your operational needs and growth objectives.
            </p>
          </div>

          <div className="blurb center_blurb">
            <h4>State-of-the-Art Infrastructure:</h4>
            <p>
              Benefit from our cutting-edge infrastructure that ensures high
              availability, scalability, and performance. We leverage
              industry-leading technologies to deliver a robust foundation for
              your critical applications and data.
            </p>
          </div>

          <div className="blurb ltr">
            <h4> 24/7 Monitoring and Support</h4>
            <p>
              Our dedicated team of experts provides round-the-clock monitoring
              and support. We proactively identify and address issues, ensuring
              the continuous availability and optimal performance of your
              dedicated servers.
            </p>
          </div>
        </div>

        {/* center column of the row */}
        <div className="center_col"></div>

        {/* Right column of the row */}
        <div className="right_col">
          <div className="blurb">
            <h4>Scalability on Demand:</h4>
            <p>
              Experience the flexibility to scale your resources based on
              demand. Our dedicated server solutions are designed to grow with
              your business, providing the agility needed in today's dynamic
              business environment.
            </p>
          </div>

          <div className="blurb center_blurb">
            <h4>Security First Approach:</h4>
            <p>
              - Security is paramount in our service delivery. We implement
              stringent security measures to safeguard your data and
              applications, including advanced firewalls, regular security
              audits, and data encryption protocols
            </p>
          </div>

          <div className="blurb rtl">
            <h4>Backup and Disaster Recovery:</h4>
            <p>
              Ensure the integrity and availability of your data with our robust
              backup and disaster recovery solutions. We have tailored
              strategies to minimize downtime and data loss in the event of
              unforeseen circumstances
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Capabilities;
