import React from "react";
import tick from "../assets/new/tick.png";

const Hero = () => {
  return (
    <section className="heroSection">
      <h1>
        Welcome to Saprise Consultancy - Your Gateway to cutting-edge ERP
        technology with SAP Business One, Innovative Web Solutions and Cloud
        Services.
      </h1>
      <p>
        Trust Saprise Consultancy for professional, engaging, and transformative
        solutions that drive your business to new heights.
      </p>
      <div className="icon_text">
        <p className="icon_cont">
          <span>
            <img src={tick} className="tick" alt="tick" />
          </span>
          Tailored Solutions
        </p>
        <p className="icon_cont">
          <span>
            <img src={tick} className="tick" alt="tick" />
          </span>
          24/7 Support
        </p>
        <p className="icon_cont">
          <span>
            <img src={tick} className="tick" alt="tick" />
          </span>
          Scalability
        </p>
      </div>

      <button
        onClick={() => {
          document
            .getElementById("contactus")
            .scrollIntoView({ behavior: "smooth" });
        }}
      >
        Contact Us
      </button>
    </section>
  );
};

export default Hero;
