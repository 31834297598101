import React from "react";
import Navbar from "./components/Navbar";
import "./styles.scss";
import Header from "./components/Header";
import AboutUs from "./components/AboutUs";
import Capabilities from "./components/Capabilities";
import Difference from "./components/Difference";
import Trusted from "./components/Trusted";
import Footer from "./components/Footer";
import Footerbar from "./components/Footerbar";
import ContactUs from "./components/ContactUs";
import Faqs from "./components/Faqs";

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Header />
      <AboutUs />
      <Capabilities />
      <Difference />
      <Trusted />
      <ContactUs />
      <Faqs />
      <Footer />
      <Footerbar />
    </div>
  );
};

export default App;
