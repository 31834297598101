import React from "react";

const Trusted = () => {
  return (
    <>
      <section className="Trusted">
        <h2>Trusted by Thousands Worldwide</h2>
        <div className="count">
          <div>
            <h4>10</h4>
            <p>Countries Served</p>
          </div>
          <div>
            <h4>100+</h4>
            <p>Customers Worldwide</p>
          </div>
          <div>
            <h4>50+</h4>
            <p>Servers Running</p>
          </div>
          <div>
            <h4>5 Y</h4>
            <p>In Business</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Trusted;
