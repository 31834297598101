import React from "react";
import Star from "../assets/new/star.png";

const AboutUs = () => {
  return (
    <section className="aboutus" id="aboutus">
      <div className="aboutus__img">
        <img src={require("../assets/new/about.jpg")} alt="no img found" />
      </div>
      <div className="aboutus__content">
        <h1>About Us</h1>
        <p>
          Welcome to Saprise Consultancy, your trusted partner for SAP Business
          One solutions and comprehensive Cloud Server Services. Headquartered
          in the United States, we take pride in our proven track record of
          successfully delivering projects across diverse industries, including
          Manufacturing, Service, Mobile Assembly, and FMCG Distribution.
        </p>

        <p className="para2">
          At Saprise Consultancy, we specialize in SAP Business One, a powerful
          and scalable ERP (Enterprise Resource Planning) solution designed to
          streamline and enhance your business processes.
        </p>
        <p className="para2">
          In addition to our SAP Business One expertise, Saprise Consultancy
          offers cutting-edge Cloud Server Services. Whether you are a small
          startup or an established enterprise, our cloud solutions provide
          scalability, security, and reliability, allowing you to focus on your
          core business activities while we take care of the technical
          infrastructure.
        </p>
        {/* <div className="blurb_modules">
          <div className="blurb_container">
            <div className="blurb_head">
              <img src={Star} alt="star" />
              <h4>Manufacturing</h4>
            </div>
            <div className="blurb_content">
              <p>
                In the ever-evolving manufacturing sector, efficiency and
                precision are paramount. Our SAP Business One implementations
                optimize production processes.
              </p>
            </div>
          </div>

          <div className="blurb_container">
            <div className="blurb_head">
              <img src={Star} alt="star" />
              <h4>Mobile Assembly</h4>
            </div>
            <div className="blurb_content">
              <p>
                In the rapidly advancing realm of mobile assembly, staying ahead
                requires agile solutions. SAP Business One enables mobile
                assemblers to enhance productivity,
              </p>
            </div>
          </div>

          <div className="blurb_container">
            <div className="blurb_head">
              <img src={Star} alt="star" />
              <h4>FMCG Distribution</h4>
            </div>
            <div className="blurb_content">
              <p>
                Fast-moving consumer goods demand swift and accurate operations.
                Our SAP Business One implementations for FMCG distributors
              </p>
            </div>
          </div>
        </div> */}
        {/* <button
          onClick={() =>
            document
              .getElementById("contactus")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          Contact Us
        </button> */}
      </div>
    </section>
  );
};

export default AboutUs;
