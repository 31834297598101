import React from "react";
import logo from "../assets/new/logo_black.png";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md";
const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="footer">
        <div className="footerContent">
          <div className="about">
            <img src={logo} />
            <p>
              Our Cloud Management Services for Dedicated Servers are designed
              to empower your business with top-tier infrastructure, seamless
              performance, and expert support.
            </p>

            <div className="icons">
              <div>
                <a href="#" target="__blank">
                  <AiOutlineTwitter />
                </a>
              </div>
              <div>
                <a href="#" target="__blank">
                  <FaFacebookF />
                </a>
              </div>
              <div>
                <a href="#" target="__blank">
                  <AiFillInstagram />
                </a>
              </div>
              <div>
                <a href="#" target="__blank">
                  <BsLinkedin />
                </a>
              </div>
            </div>
          </div>
          <div className="quickmenu">
            <h1>Quick Menu</h1>
            <div
              onClick={() =>
                document
                  .getElementById("home")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Home
            </div>
            <div
              onClick={() =>
                document
                  .getElementById("aboutus")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              About Us
            </div>
            <div
              onClick={() =>
                document
                  .getElementById("services")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Services
            </div>
            <div
              onClick={() =>
                document
                  .getElementById("Why Choose Us?")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Why Choose Us ?
            </div>
            <div
              onClick={() =>
                document
                  .getElementById("faqs")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              FAQ'S
            </div>
          </div>
          <div className="contact">
            <div>
              <HiMail className="icon" />
              <p>info@sapriseconsultancy.com</p>
            </div>
            <div>
              <HiMail className="icon" />
              <p>sales@sapriseconsultancy.com</p>
            </div>
            <div>
              <MdLocationOn className="icon" />
              <p>73 Central Ave, Albany NY 12206 USA</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
