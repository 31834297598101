import React, { useState } from "react";
import emailjs from "emailjs-com";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdOutlineErrorOutline } from "react-icons/md";

const ContactUs = () => {
  const [err, setErr] = useState(false);
  const [success, setsuccess] = useState(false);
  const [isSending, setisSending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setisSending(true);
    emailjs
      .sendForm(
        "SapriseConsultancyEMAIL",
        "template_2rsvn5c",
        e.target,
        "PgiWTt-QRefKnZhD4"
      )
      .then(
        (result) => {
          setsuccess(true);
          setErr(false);
          e.target.reset();
          setisSending(false);
        },
        (error) => {
          setsuccess(false);
          setErr(true);
          setisSending(false);
        }
      );
  };
  return (
    <section className="contactus" id="contactus">
      <h1>Contact Us</h1>
      <p>
        Embark on a digital journey with SAPrise Consultancy – where innovation
        meets excellence. Contact us today to explore how our web development,
        design, and digital marketing services can propel your business to new
        heights. Let's build a digital future together!
      </p>
      <form
        onSubmit={handleSubmit}
        onChange={() => {
          setsuccess(false);
          setErr(false);
        }}
      >
        <div className="row">
          <input
            type="text"
            name="name"
            placeholder="First Name"
            required
          ></input>
          <input
            type="text"
            name="lname"
            placeholder="Last Name"
            required
          ></input>
        </div>

        <div className="row">
          <input type="email" name="email" placeholder="Email" required></input>
          <input type="tel" name="phone" placeholder="Phone" required></input>
        </div>
        <textarea
          type="message"
          name="message"
          placeholder="Message"
          required
        ></textarea>
        <button>
          {isSending ? (
            <>
              <div class="loader"></div>Sending...
            </>
          ) : (
            "Submit Request"
          )}
        </button>
      </form>
      {success ? (
        <div className="Success">
          <BsFillCheckCircleFill /> <p>Successfully Submitted</p>
        </div>
      ) : (
        err && (
          <div className="Error">
            {" "}
            <MdOutlineErrorOutline /> <p>Something Went Wrong !</p>
          </div>
        )
      )}
    </section>
  );
};

export default ContactUs;
